html {
  @apply bg-theme-1;
  &.dark {
    @apply bg-dark-2;
    body {
      @apply text-gray-300;
      *,
      ::before,
      ::after {
        @apply border-dark-3;
      }
    }
  }
  body {
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    // @apply overflow-x-hidden py-3 px-8 font-roboto text-sm text-gray-800;
    @apply overflow-x-hidden font-roboto text-sm text-gray-800;
    // @include media-breakpoint-down(sm) {
    //   @apply px-3;
    // }
  }
}

@layer components {
  // .body-content:not(.content) {
  //   @apply py-3 px-8;
  //   @include media-breakpoint-down(sm) {
  //     @apply px-3;
  //   }
  // }

  .body-content:not(.content) {
    @apply py-0 px-0;
    @include media-breakpoint-down(sm) {
      @apply px-0;
    }
  }

  // Excluding Conatiner Element
  // .body-content:not(.content) {
  //   @apply px-0;
  // }
}
