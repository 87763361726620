@layer components {
  .top-nav {
    @apply z-50 relative;
    background: #184a6f; // CUSTOM CODE
    @include media-breakpoint-down(md) {
      @apply hidden;
    }
    > ul {
      @for $i from 1 through 50 {
        > li:nth-child(#{$i}) {
          & > a {
            &:not(.top-menu--active) {
              @apply opacity-0;
              transform: translateY(50px);
              animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
              animation-fill-mode: forwards;
              animation-delay: #{$i * 0.1}s;
            }
            &.top-menu--active {
              animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
              animation-fill-mode: forwards;
            }
          }
        }
      }
      padding: 0px 50px;
      @apply flex flex-wrap;
      @include media-breakpoint-down(xl) {
        @apply p-0 pb-3;
      }
      li {
        @apply relative;
        &:hover {
          > .top-menu > .top-menu__title > .top-menu__sub-icon {
            @apply transform -rotate-90;
          }
          .top-menu:not(.top-menu--active) .top-menu__icon:before {
            @apply bg-theme-4 dark:bg-dark-4;
          }
          > ul {
            @apply block;
          }
        }
        .top-menu {
          height: 55px;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          @apply flex items-center px-5 mr-1 text-white relative;
          @include media-breakpoint-down(xl) {
            @apply rounded-full;
          }
          &.top-menu--active {
            @apply bg-theme-2 dark:bg-dark-1;
            &:before {
              content: "";
              width: 20px;
              height: 20px;
              margin-left: -20px;
              transform: rotate(90deg) scale(1.04);
              background-size: 100%;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A");
              @apply absolute bottom-0 left-0;
              @include media-breakpoint-down(xl) {
                @apply hidden;
              }
            }
            &:after {
              content: "";
              width: 20px;
              height: 20px;
              margin-right: -20px;
              transform: rotate(180deg) scale(1.04);
              background-size: 100%;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A");
              @apply absolute bottom-0 right-0;
              @include media-breakpoint-down(xl) {
                @apply hidden;
              }
            }
            .top-menu__icon {
              @apply text-theme-3 dark:text-white;
              &:before {
                @apply bg-dark-1;
              }
            }
            .top-menu__title {
              @apply text-black font-medium dark:text-white;
            }
          }
          .top-menu__icon {
            @apply dark:text-gray-500;
          }
          .top-menu__title {
            @apply dark:text-gray-500;
          }
          &:not(.top-menu--active) .top-menu__icon:before {
            content: "";
            z-index: -1;
            @apply absolute w-full h-full top-0 left-0 rounded-t-lg transition ease-in duration-100;
            @include media-breakpoint-down(xl) {
              @apply rounded-full;
            }
          }
          .top-menu__title {
            @apply ml-3 flex items-center whitespace-nowrap;
            .top-menu__sub-icon {
              @apply transition ease-in duration-100 w-4 h-4 ml-2;
              @include media-breakpoint-down(xl) {
                @apply hidden;
              }
            }
          }
        }
        ul {
          left: 100%;
          margin-left: -4px;

          box-shadow: 0px 3px 20px #0000000b;
          @apply bg-theme-24;
          @apply hidden w-56 absolute rounded-md z-20 px-0 top-0 -mt-5;
          @include media-breakpoint-down(xl) {
            @apply left-0 mt-14 ml-0;
          }
          li {
            @apply px-5 relative;
            .top-menu {
              @apply px-0 mr-0;
              .top-menu__icon:before {
                @apply hidden;
              }
              .top-menu__title {
                @apply w-full;
                .top-menu__sub-icon {
                  @apply ml-auto;
                }
              }
            }
          }
          ul {
            @apply bg-theme-24;
            @apply hidden rounded-md mt-0 ml-0;
            @include media-breakpoint-down(xl) {
              left: 100%;
            }
          }
        }
      }
    }
  }

  @keyframes intro-top-menu-animation {
    100% {
      @apply opacity-100;
      transform: translateY(0px);
    }
  }

  @keyframes intro-active-top-menu-animation {
    100% {
      @apply z-10;
    }
  }

  .dark {
    .top-nav {
      > ul {
        li {
          .top-menu {
            &.top-menu--active {
              &:before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23293145'/%3E%3C/svg%3E%0A");
              }
              &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23293145'/%3E%3C/svg%3E%0A");
              }
            }
          }
          ul {
            background: #181f29e6;
          }
        }
      }
    }
  }
}
