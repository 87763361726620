@layer components {
  .float-container {
    position: fixed;
    top: 33%;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    z-index: 9999;
  }

  .float-container a {
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 48px;
    margin-right: -150px;
    margin-bottom: 10px;
    padding: 10px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    color: white;
    border-color: #46b8da;
    border-radius: 5px 0 0 5px;
    background-color: #eb690b;
    -webkit-box-shadow: 0 2px 4px #7d7d7d;
    box-shadow: 0 2px 4px #7d7d7d;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .float-container a:hover {
    margin-right: 0;
    background-color: #c45100;
    -webkit-box-shadow: 0 2px 4px #7d7d7d;
    box-shadow: 0 2px 4px #7d7d7d;
  }

  /* Icon settings - remove if not needed*/
  .float-container .icon:before {
    font-family: "Font Awesome 5 Free";
    margin-right: 25px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  /* Media queries */
  @media screen and (max-width: 440px) {
    .float-container .icon:last-child {
      display: none;
    }
    .float-container {
      position: fixed;
      top: auto;
      bottom: 0;

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;

      width: 100%;

      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: auto;
      -webkit-box-align: auto;
      -ms-flex-align: auto;
      align-items: auto;
    }
    .float-container a.icon {
      right: 0;
      bottom: 0;

      width: 100%;
      margin-right: 0;
      margin-bottom: 0;
      padding: 5px;

      border-radius: 0;
      -webkit-box-shadow: 0 0 0 #7d7d7d;
      box-shadow: 0 0 0 #7d7d7d;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-left: 1px solid darkorange;
      border-right: 1px solid darkorange;
    }
  }
}
