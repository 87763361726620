/* .thumbnail-container {
    position: relative;
 
  }
  
  .thumbnail-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000000;
  }

  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .thumbnail-container:hover .overlay {
    opacity: 0.5;
  }

  
  
  .thumbnail-container:hover .middle {
    opacity: 1;
  }
   */
   .thumbnail-image {
    max-width: 100%;
    height: 150px;
  }
  .thumbnail-container{
    position: relative;
    display: inline-block;
  }
  .thumbnail-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  .thumbnail-container:hover .overlay {
    opacity: 1;
  }
  .overlay .button-group {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }