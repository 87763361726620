/*Profile Pic Start*/
.picture-container {
  position: relative;
  cursor: pointer;
  text-align: center;
}
.picture {
  position: relative;
  /* Custom Code */
  width: 106px;
  height: 106px;
  background-color: #999999;
  border: 4px solid #cccccc;
  color: #ffffff;
  border-radius: 50%;
  margin: 0px auto;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.picture:hover {
  border-color: #2ca8ff;
}
.content.ct-wizard-green .picture:hover {
  border-color: #05ae0e;
}
.content.ct-wizard-blue .picture:hover {
  border-color: #3472f7;
}
.content.ct-wizard-orange .picture:hover {
  border-color: #ff9500;
}
.content.ct-wizard-red .picture:hover {
  border-color: #ff3b30;
}
.picture input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.picture-src {
  position: relative;
  /* Custom Code */
  width: 100%;
}
/*Profile Pic End*/
