@layer components {
    .report-box {
        @apply relative;
        &:before {
            content: "";
            width: 90%;
            background: #f9fafc;
            box-shadow: 0px 3px 20px #0000000b;
            @apply h-full mt-3 absolute rounded-md mx-auto inset-x-0;
        }
        .report-box__icon {
            width: 28px;
            height: 28px;
        }
        .report-box__indicator {
            padding-top: 3px;
            padding-bottom: 3px;
            @apply flex rounded-full text-white text-xs pl-2 pr-1 items-center font-medium;
        }
    }

    .dark {
        .report-box {
            &:before {
                background: #2d3650;
            }
        }
    }
}
