#chip-input {
    width: 100%;
    height: 45px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 2px solid #d4d5d6;
    color: #565656;
    -webkit-appearance: none;
  }
  
  #chip-input:focus {
    border-color: cornflowerblue;
    outline: none;
  }
  
  #chip-input.has-error {
    border-color: tomato;
  }
  
  #chip-input-error {
    margin: 0;
    font-size: 90%;
    color: tomato;
  }
  
  #chip-input-tag-item {
    background-color: #d4d5d6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
  }
  
  #chip-input-tag-item > .button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* CUSTOM RADIO BUTTON with background */
.radio-bg {
  /* background: #454857; */
  padding: 4px;
  border-radius: 3px;
  /* box-shadow: inset 0 0 0 3px rgba(35, 33, 45, 0.3),
		0 0 0 3px rgba(185, 185, 185, 0.3); */
  position: relative;
}

.radio-bg input {
  width: auto;
  height: 100%;
  appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px 8px;
  /* background: #454857; */
  border: 1px solid #484848;
  color: #5a5a5a;
  transition: all 100ms linear;
}

.radio-bg input:checked {
  background-image: linear-gradient(180deg, #57ab6a, #57ab6a);
  color: #fff;
  box-shadow: 0 1px 1px #0000002e;
  /* text-shadow: 0 1px 0px #79485f7a; */
}

.radio-bg input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}

/* CUSTOM RADIO BUTTON with background*/