@layer components {
    .breadcrumb {
        @apply flex items-center text-theme-1 dark:text-theme-10;
        &.breadcrumb--light {
            @apply h-full ml-10 pl-10 border-l border-theme-29 text-theme-22 dark:border-dark-3 dark:text-theme-10;
            @include media-breakpoint-down(md) {
                @apply ml-0 pl-0 border-l-0;
            }
            .breadcrumb--active {
                @apply text-theme-21 dark:text-gray-600;
            }
        }
        .breadcrumb__icon {
            width: 14px;
            height: 14px;
            @apply mx-1;
        }
        .breadcrumb--active {
            @apply text-gray-800 cursor-text dark:text-gray-600;
        }
    }
}
