@layer components {
  // Font Awesome icons

  // .fa {
  //   color: rgba(0, 0, 0, 0);
  //   border: 1px solid #fff;
  // }

  // Slider Carousel

  .carousel .control-dots {
    margin: 0 !important;
  }

  //**** <!--BEGIN : Scrollbar -->****

  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(59, 59, 59, 0.3);
    background-color: #555;
  }

  //**** <!--END : Scrollbar -->****

  // Scrollable Card - box
  .scroll-box {
    max-height: 70vh;
    overflow-y: scroll;
  }

  .btn-primary {
    background-color: #093a60 !important;
  }

  .top-bar-bg {
    // background: #093a60;
    background: #1d5782;
  }

  // MODAL CSS

  .required:after {
    content: " *";
    // color: red;
    @apply text-theme-6;
  }

  .modal {
    margin: 0;
    z-index: 100000;
    overflow: auto;
  }
  .modal-fullscreen {
    width: 100vw !important;
    height: 100vh !important;
  }

  .modal-xxl {
    width: 90vw !important;
    height: 100%;
  }

  .modal-fit {
    width: fit-content !important;
    height: fit-content !important;
  }
  // MODAL CSS

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-top: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #1e2125;
    background-color: #e9ecef;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd;
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
  }
  .dropdown-menu.show {
    display: block;
    background: #fff;
  }
  .dropdown-header {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
  }
  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1rem;
    color: #212529;
  }

  .dropdown-menu {
    margin-top: -1px;
    // border-top: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
    @apply z-50 shadow-lg border rounded;
    .optgroup-header {
      padding: 9px 10px;
      @apply font-medium;
    }
    .option {
      padding: 9px 13px;
      .highlight {
        @apply bg-theme-6 text-white;
      }
      &.active {
        @apply bg-theme-1 text-white;
      }
      &.show {
        @apply bg-theme-1 text-white;
      }
      &:hover:not(.active) {
        @apply bg-gray-300 text-gray-800;
      }
    }
    &.plugin-dropdown_input .dropdown-input-wrap {
      @apply p-2;
      input {
        @apply outline-none border  rounded py-2 px-3;
      }
    }
    .dropdown-header {
      @apply px-3 bg-gray-100 border-gray-300;
      .dropdown-header-close {
        @apply cursor-pointer opacity-100 text-gray-500 mr-0.5;
      }
    }
  }

  // Typeahead
  .rbt-menu {
    @apply shadow border;
  }

  // Carousel - React Slider

  .carousel .control-dots .dot {
    background: #727272 !important;
  }
}
