@layer components {
  .content {
    border-radius: 0px;
    padding: 0px 22px;
    //@apply min-w-0 min-h-screen bg-theme-2 flex-1 pb-10 dark:bg-dark-1;
    @apply min-w-0 bg-gray-100 flex-1 pb-10 dark:bg-dark-1;
    min-height: calc(100vh - 3rem); // CUSTOM CODE
    @include media-breakpoint-down(md) {
      @apply px-4 max-w-full;
    }
    &:before {
      content: "";
      @apply w-full h-px block;
    }
  }
}
