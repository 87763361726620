// $sidebar-bg-color: #0c4c7d;
// $submenu-bg-color: #0f4066;
// $submenu-bg-color-collapsed: rgba(#0c4c7d, 0.95);
$sidebar-bg-color: #111111;
$submenu-bg-color: rgb(14, 14, 14);
$submenu-bg-color-collapsed: rgba(#111111, 0.95);

$sidebar-color: #d6d6d6;
$highlight-color: #ffffff;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 32px;
// $sidebar-width: 236px !default;
$sidebar-width: 220px;

@import "~react-pro-sidebar/dist/scss/styles.scss";
